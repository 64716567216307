import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material';

const startAdornment = (
    <InputAdornment position="start">
        <SearchIcon fontSize="small" sx={{ color: 'muiBlack' }} />
    </InputAdornment>
);

const StyTextField = styled(TextField, {
    name: 'StyTextField',
})({
    '& .MuiInputBase-input': {
        fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '6px',
    },
    '& .MuiInputAdornment-positionStart': {
        marginRight: '4px',
    },
});

type Props = {
    parentFilterInstance: FlowAnyFunction;
};

const filterType = 'greaterThanOrEqual';

export default forwardRef(({ parentFilterInstance }: Props, ref) => {
    const { t } = useTranslation();
    const [value, setValue] = useState('');

    // Expose AG Grid Filter Lifecycle callbacks
    useImperativeHandle(ref, () => ({
        // @ts-expect-error [TS migration] Was not detected by flow
        onParentModelChanged(parentModel) {
            // When the filter is empty we will receive a null value here
            setValue(parentModel ? parentModel.filter : '');
        },
    }));

    // @ts-expect-error [TS migration] Was not detected by flow
    const onChange = (input) => {
        if (input.target.value === '') {
            // Clear the filter
            // @ts-expect-error [TS migration] Was not detected by flow
            parentFilterInstance((instance) => {
                instance.onFloatingFilterChanged(filterType, null);
            });
            return;
        }

        // @ts-expect-error [TS migration] Was not detected by flow
        parentFilterInstance((instance) => {
            instance.onFloatingFilterChanged(filterType, input.target.value);
        });
    };

    return (
        <StyTextField
            value={value}
            size="small"
            placeholder={t('budgetProposal.search')}
            InputProps={{
                startAdornment,
                type: 'number',
                disableInjectingGlobalStyles: true,
            }}
            onChange={onChange}
            variant="outlined"
        />
    );
});
