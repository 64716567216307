import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {
    text: string;
    onClick?: () => void;
    dataTestId?: string;
};

const AddButton = ({
    text,
    onClick,
    dataTestId,
}: Props): React.ReactElement => (
    <Button variant="contained" onClick={onClick} data-testid={dataTestId}>
        <AddIcon fontSize="small" sx={{ marginRight: '0.5rem' }} />
        <Typography variant="h5" color="inherit">
            {text}
        </Typography>
    </Button>
);
AddButton.defaultProps = {
    onClick: () => {},
    dataTestId: '',
};

export default React.memo(AddButton);
