import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from 'components/Loading';

const LoadingAdornment = (props: {
    show: boolean;
    ['data-testid']?: string;
}): React.ReactElement =>
    props.show ? (
        <InputAdornment data-testid={props['data-testid']} position="end">
            <Loading small />
        </InputAdornment>
    ) : (
        <></>
    );
LoadingAdornment.defaultProps = { 'data-testid': '' };
export default LoadingAdornment;
