import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';

const StyHelperBox = styled(Box, { name: 'HelperBox' })(({ theme }) => ({
    borderRadius: '8px',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
    gap: '16px',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    '& a': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
    },
}));

const HelperBox = ({ children }: { children: React.ReactElement }) => {
    return (
        <StyHelperBox>
            <LightbulbCircleIcon
                color="primary"
                sx={{
                    width: 24,
                    height: 24,
                    flexShrink: 0,
                }}
            />
            {children}
        </StyHelperBox>
    );
};

export default HelperBox;
