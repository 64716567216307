import * as React from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button, { ButtonProps, ButtonTypeMap } from '@mui/material/Button';
import SubmitModal from 'components/Submits/SubmitModal';
import { useTranslation } from 'react-i18next';
import { SxProps, Theme } from '@mui/material';
import Flex from 'ui/Flex/Flex';
import { styled } from '@mui/material/styles';
import { omit } from 'lodash';

type Props = {
    onClick?: FlowAnyFunction;
    className?: string;
    sx?: SxProps<Theme>;
    label: React.ReactNode;
    color?: ButtonTypeMap['props']['color'];
    icon?: React.ReactNode;
    extra: ButtonProps;
};

const StyButtonWithIcon = styled(Button, {
    name: 'StyButtonWithIcon',
})(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'non',
    },
    display: 'flex',
    padding: '6px 16px',
    svg: {
        marginRight: '6px',
    },
}));

export function ResponsiveButton(props: Props): React.ReactElement {
    const iconButtonCompatibleProps: IconButtonProps = omit(
        props.extra,
        'endIcon',
    );
    return (
        <Flex align="center">
            <StyButtonWithIcon
                color={props.color}
                onClick={props.onClick}
                className={props.className}
                sx={props.sx}
                {...props.extra}
            >
                {props.icon}
                {props.label}
            </StyButtonWithIcon>
            <IconButton
                onClick={props.onClick}
                color={props.color}
                className={props.className}
                sx={{ display: { xs: 'flex', sm: 'none' }, ...props.sx }}
                {...iconButtonCompatibleProps}
                size="large"
            >
                {props.icon}
            </IconButton>
        </Flex>
    );
}

ResponsiveButton.defaultProps = {
    onClick: () => {},
};

export function ResponsiveButtonWithConfirmation(props: {
    onSubmitClick: FlowAnyFunction;
    label: string;
    color?: ButtonTypeMap['props']['color'];
    icon: React.ReactNode;
    extra?: {};
    dialogTitle: string;
    children: React.ReactNode;
}): React.ReactElement {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    // Flow is not detecting default props
    // https://github.com/facebook/flow/issues/6644
    // we must explicitly test on undefined and set extra
    let { extra } = props;
    if (extra === undefined) {
        extra = {};
    }
    return (
        <>
            <ResponsiveButton
                onClick={() => setOpen(true)}
                color={props.color}
                extra={extra}
                icon={props.icon}
                label={props.label}
            />
            <SubmitModal
                t={t}
                submit={props.onSubmitClick}
                // @ts-expect-error [TS migration] Was not detected by flow
                dialogText={props.children}
                dialogTitle={props.dialogTitle}
                open={open}
                setOpen={setOpen}
            />
        </>
    );
}

ResponsiveButtonWithConfirmation.defaultProps = {
    extra: {} as FlowAnyObject,
    className: '',
};
