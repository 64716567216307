import React from 'react';
import Chip, { ChipProps } from '@mui/material/Chip';
import { CellRendererParams } from 'routes/BudgetModule/config/types';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

type Colors = 'red' | 'green' | 'orange' | 'black';

const colors: Record<Colors, React.CSSProperties> = {
    red: {
        border: '1px solid #F14F5B',
        background: '#ebbfc2',
        color: '#F14F5B',
    },
    green: {
        border: '1px solid #388866',
        background: '#bdddd0',
        color: '#388866',
    },
    orange: {
        border: '1px solid #E9815F',
        background: '#f7d1c4',
        color: '#E9815F',
    },
    black: {
        border: '1px solid white',
        color: 'white',
        background: 'white',
    },
};

const StyChip = styled(Chip, {
    shouldForwardProp: (prop: keyof ChipProps | 'styledColor') =>
        prop !== 'styledColor',
    name: 'StyChip',
})(({ styledColor }: ChipProps & { styledColor: Colors }) => ({
    fontSize: '12px',
    ...colors[styledColor],
}));

export type ChipCellRendererParams = CellRendererParams<unknown, string> & {
    getColor: (value: string) => string;
    size?: 'medium' | 'small';
    getTooltip?: (params: CellRendererParams) => string;
};

export default function ChipCellRenderer(
    params: ChipCellRendererParams,
): React.ReactElement | null {
    const {
        value,
        valueFormatted,
        getColor,
        size = 'medium',
        getTooltip,
    } = params;
    if (!value) return null;
    const color = (getColor && getColor(value)) ?? '';
    const chip = (
        <StyChip
            label={valueFormatted}
            size={size}
            styledColor={color as Colors}
        />
    );
    if (!getTooltip) return chip;
    return <Tooltip title={getTooltip(params)}>{chip}</Tooltip>;
}
