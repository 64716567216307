import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    Popper,
    PopperProps,
    SxProps,
    TextField,
    Theme,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Flex from 'ui/Flex/Flex';
import { AutocompletAble } from './types';

type Props<T> = {
    selectedValues: AutocompletAble<T>[];
    availableValues: AutocompletAble<T>[];
    setSelectedValues: DispatchState<AutocompletAble<T | null>[]>;
    label?: string;
    sort?: boolean;
    onClose?: () => void;
    onConfirm?: () => void;
    sx?: SxProps<Theme>;
    confirmCondition?: boolean;
    sortOptions?: (options: AutocompletAble<T>[]) => AutocompletAble<T>[];
};

export function sortAlphabetically<T>(
    options: AutocompletAble<T>[],
): AutocompletAble<T>[] {
    return options.sort(
        (a, b) => -new Intl.Collator().compare(b.label, a.label),
    );
}

const OrderedAutocompleteWithConfirm = <T,>({
    availableValues,
    selectedValues,
    setSelectedValues,
    label = '',
    onClose = () => {},
    sort = true,
    onConfirm = () => {},
    sx = { m: '2rem 0' },
    confirmCondition = true,
    sortOptions = sortAlphabetically,
}: Props<T>): React.ReactElement => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<boolean>(false);
    const CustomPopper = (
        props: JSX.IntrinsicAttributes &
            Pick<PopperProps, 'anchorEl' | 'open'> &
            React.RefAttributes<HTMLDivElement>,
    ) => <Popper {...props} open={props.open} sx={{ position: 'relative' }} />;

    const getOptions = (options: AutocompletAble<T>[]) =>
        sort ? sortOptions(options) : options;

    const options = getOptions(availableValues);

    return (
        <Box sx={sx}>
            <Autocomplete
                disableClearable
                disableCloseOnSelect
                multiple
                open={open}
                openText={t('common.open')}
                closeText={t('common.close')}
                options={options}
                getOptionLabel={(option) => option.label ?? ''}
                getOptionDisabled={(option) => !!option.disabled}
                value={selectedValues}
                limitTags={3}
                onOpen={() => setOpen(true)}
                PopperComponent={CustomPopper}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        color="primary"
                        label={label}
                    />
                )}
                isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                }
                renderOption={(props, id, { selected }) => (
                    <>
                        <li
                            {...props}
                            style={{ paddingBottom: id.isAll ? '10px' : '0px' }}
                            key={id.label}
                        >
                            <Checkbox
                                icon={
                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                }
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                disabled={id.disabled}
                            />
                            {id.label}
                        </li>
                        {options.indexOf(id) === options.length - 1 && (
                            <li>
                                <Flex justifyContent="space-between">
                                    <Button
                                        onClick={() => {
                                            onClose();
                                            setOpen(false);
                                        }}
                                    >
                                        {t('common.cancel')}
                                    </Button>
                                    <Button
                                        disabled={!confirmCondition}
                                        variant="contained"
                                        onClick={() => {
                                            onConfirm();
                                            setOpen(false);
                                        }}
                                    >
                                        {t('common.confirm')}
                                    </Button>
                                </Flex>
                            </li>
                        )}
                    </>
                )}
                renderTags={(ids) =>
                    ids.map((id) => (
                        <Chip
                            key={id.label}
                            color="primary"
                            label={`${selectedValues.indexOf(id) + 1} - ${
                                id.label
                            }`}
                        />
                    ))
                }
                onChange={(_, ids) => {
                    setSelectedValues(ids);
                }}
                onClose={() => {
                    onClose();
                    setOpen(false);
                }}
            />
        </Box>
    );
};

export default OrderedAutocompleteWithConfirm;
