import * as React from 'react';

import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled } from '@mui/material';

const getHoverColor = (isDeleteAction: boolean, isAddAction: boolean) => {
    if (isDeleteAction) {
        return 'red';
    }
    if (isAddAction) {
        return 'green';
    }
    return '#06c';
};

const StyEditorIcon = styled(IconButton, {
    shouldForwardProp: (prop: keyof IconButtonProps | keyof Props) =>
        !['isActive', 'isDeleteAction', 'isAddAction'].includes(prop),
    name: 'StyEditorIcon',
})<
    IconButtonProps & Pick<Props, 'isActive' | 'isDeleteAction' | 'isAddAction'>
>(({ isActive, isDeleteAction, isAddAction }) => {
    const hoverColor = getHoverColor(!!isDeleteAction, !!isAddAction);
    return {
        '&:hover': { backgroundColor: 'transparent' },
        '& svg': {
            fontSize: '1.8rem',
            scale: '0.8',
            color: isActive ? '#06c' : '#757575',
            fill: isActive ? '#06c' : '#757575',
        },
        '& svg:hover': {
            fill: hoverColor,
            color: hoverColor,
        },
    };
});

type Props = {
    isActive?: boolean;
    isAddAction?: boolean;
    isDeleteAction?: boolean;
    className?: string;
    children?: React.ReactNode;
    onClick: (arg1: React.SyntheticEvent<HTMLButtonElement>) => void;
};

// @ts-expect-error [TS migration] Was not detected by flow
const EditorIcon: React.AbstractComponent<Props> = React.forwardRef(
    (
        { isActive, isAddAction, isDeleteAction, ...props }: Props,
        ref: React.Ref<HTMLButtonElement>,
    ) => (
        <StyEditorIcon
            {...props}
            isActive={isActive}
            isAddAction={isAddAction}
            isDeleteAction={isDeleteAction}
            size="small"
            className={props.className}
            ref={ref}
        />
    ),
);
export default EditorIcon;
