import React, { memo, useEffect, useRef, useState } from 'react';
import { ClickAwayListener, SxProps, TextField, Theme } from '@mui/material';
import { CellRendererParams } from 'routes/BudgetModule/config/types';
import EditIcon from '@mui/icons-material/Edit';
import { endAdornment } from 'ui/AgGridReact/CurrencyInputAdornment';
import Flex from 'ui/Flex/Flex';
import { useDebounce } from 'hooks/helpers';
import { NumberFormatCustom, parseNumericInput } from 'helpers/UIHelpers';

const FORMATS = {
    number: {
        inputComponent: NumberFormatCustom,
        formatter: (value: string) => parseNumericInput(value).toString(),
    },
    string: {
        inputComponent: undefined,
        formatter: (value: string) => value,
    },
} as const;

type Params<DataT> = CellRendererParams<DataT, string> & {
    /**
     * Callback that gets called when the user changes the input. It is debounced.
     */
    onChange: (value: string) => void;
    /**
     * Defines the formatting that should be allowed in the input field.
     */
    inputType?: keyof typeof FORMATS;
    /**
     * Style that is applied to the cell on its display mode.
     */
    sx?: SxProps<Theme>;
    /**
     * Time between the last user input and the call to onChange.
     */
    debounceTime?: number; // in milliseconds
    /**
     * Boolean setting edit/display mode.
     */
    isUpdateEnabled: boolean;
};

const EditFieldCellRenderer = <DataT,>({
    value,
    formatValue,
    onChange,
    sx,
    debounceTime = 1000,
    inputType = 'number',
    isUpdateEnabled,
}: Params<DataT>): React.ReactElement => {
    const [isEditMode, setIsEditMode] = useState(false);

    const initialValue = useRef(value.toString());
    // This is the value that goes into the TextField. It changes with every user input
    // and might be different from the debounced value.
    const [inputValue, setInputValue] = useState(value.toString());

    const format = FORMATS[inputType];

    useEffect(() => {
        // Whenever the cell changes back to display mode, it saves the last input value
        // It will be used if the user ever presses escape next time they edit the cell.
        if (!isEditMode) {
            initialValue.current = inputValue;
        }
    }, [isEditMode]);

    const debouncedOnChange = useDebounce((newValue: string) => {
        onChange(newValue);
    }, debounceTime);

    const onInputChanged = (newValue: string) => {
        if (isUpdateEnabled) {
            const formattedValue = format.formatter(newValue);
            setInputValue(formattedValue);
            debouncedOnChange(formattedValue);
        }
    };

    if (!isEditMode) {
        return (
            <Flex
                justify="space-between"
                align="center"
                onClick={() => setIsEditMode(isUpdateEnabled)}
                sx={{
                    ...sx,
                    cursor: 'pointer',
                }}
            >
                <span>{formatValue?.(value) ?? value}</span>
                {isUpdateEnabled && <EditIcon color="primary" />}
            </Flex>
        );
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'Escape'].includes(e.key)) {
            if (e.key === 'Escape') {
                onInputChanged(initialValue.current);
            }
            setIsEditMode(false);
            e.stopPropagation();
        }
    };

    return (
        <ClickAwayListener
            onClickAway={() => {
                debouncedOnChange(inputValue);
                setIsEditMode(false);
            }}
        >
            <TextField
                value={inputValue}
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onInputChanged(e.target.value);
                }}
                autoFocus
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                InputProps={{
                    inputComponent: format.inputComponent,
                    disableInjectingGlobalStyles: true,
                    endAdornment,
                    sx: { height: '32px' },
                }}
            />
        </ClickAwayListener>
    );
};

export default memo(EditFieldCellRenderer);
