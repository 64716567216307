import React, { CSSProperties } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const StyColoredChipWrapper = styled(Box, {
    name: 'StyColoredChipWrapper',
    shouldForwardProp: (prop: string) =>
        !['backgroundColor', 'color'].includes(prop),
})<{
    backgroundColor: CSSProperties['backgroundColor'];
    color: CSSProperties['color'];
}>(({ backgroundColor, color }) => ({
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    height: 'max-content',
    width: 'max-content',
    borderRadius: '20px',
    padding: '0.4rem 0.8rem',
    marginRight: '1rem',
    backgroundColor,
    border: `1px solid ${color}`,
    color,
}));

type Props = {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    content: string;
    className?: string;
};

export default function ColoredChip({
    color,
    backgroundColor,
    content,
    className,
}: Props): React.ReactElement {
    return (
        <StyColoredChipWrapper
            color={color}
            backgroundColor={backgroundColor}
            className={className}
        >
            {content}
        </StyColoredChipWrapper>
    );
}
