import React from 'react';
import _ from 'lodash';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { LIGHT_TEXT } from 'styles/theme';
import {
    CircularProgress,
    Input,
    OutlinedInput,
    SxProps,
    Theme,
    Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';

type Props<T> = {
    sx?: SxProps<Theme>;
    label: string;
    value: T[];
    onChange: (arg1: T[]) => void;
    options: Array<{
        value: T;
        label: string;
    }>;
    disabled?: boolean;
    fullWidth?: boolean;
    variant?: 'outlined' | 'standard';
    loading?: boolean;
    onSelectAll?: () => void;
};

const CircularProgressWrapper = () => (
    <CircularProgress
        size="20px"
        style={{
            display: 'flex',
            marginRight: '12px',
        }}
    />
);

export default function MultiSelectWithCheckboxes<T>(
    props: Props<T>,
): React.ReactElement {
    const { t } = useTranslation();

    // @ts-expect-error [TS migration] Was not detected by flow
    const renderValue = (values) => {
        const displayValue = values
            .map(
                // @ts-expect-error [TS migration] Was not detected by flow
                (item) =>
                    props.options.find((option) => option.value === item)
                        ?.label ?? '',
            )
            .join(', ');
        return (
            <Tooltip title={displayValue}>
                <MenuItem style={{ padding: '0' }}>{displayValue}</MenuItem>
            </Tooltip>
        );
    };
    const randomId = _.uniqueId();
    return (
        <FormControl
            fullWidth={props.fullWidth}
            sx={props.sx}
            variant={props.variant}
            margin={props.variant === 'outlined' ? 'dense' : 'none'}
            size={props.variant === 'outlined' ? 'small' : 'medium'}
        >
            <InputLabel id={`multi-select-${randomId}`} sx={{ ...LIGHT_TEXT }}>
                {props.label}
            </InputLabel>
            <Select
                variant="standard"
                data-testid={`multi-select-${props.label}`}
                labelId={`multi-select-${randomId}`}
                multiple
                value={props.value}
                /* We should not pass T but only strings, because objects are invalid HTML values for <select /> */
                // @ts-expect-error MUI migration
                onChange={(
                    e: React.ChangeEvent<{
                        name?: string;
                        value: (T | string)[];
                    }>,
                ) => {
                    const filteredValues = e.target.value.filter(
                        (item) => item !== 'selectAll',
                    );
                    if (e.target.value.length === filteredValues.length) {
                        props.onChange(filteredValues as T[]);
                    } else {
                        if (!props.onSelectAll) {
                            console.error(
                                'onSelectAll function not defined, impossible case',
                            );
                            return;
                        }
                        props.onSelectAll();
                    }
                }}
                renderValue={renderValue}
                IconComponent={
                    props.loading
                        ? CircularProgressWrapper
                        : KeyboardArrowDownIcon
                }
                sx={{ '& .MuiSelect-icon': { marginRight: '7px' } }}
                disabled={props.disabled || props.loading}
                input={
                    props.variant === 'outlined' ? (
                        <OutlinedInput
                            id={`input-${randomId}`}
                            label={props.label}
                            disabled={props.disabled}
                        />
                    ) : (
                        <Input
                            id={`input-${randomId}`}
                            disabled={props.disabled}
                        />
                    )
                }
            >
                {!!props.onSelectAll && props.options.length > 1 && (
                    <MenuItem key="selectAll" value="selectAll">
                        <Checkbox
                            checked={
                                props.options.length === props.value.length
                            }
                        />
                        <ListItemText
                            primary={t('common.selectAll')}
                            sx={{ ...LIGHT_TEXT }}
                        />
                    </MenuItem>
                )}

                {props.options.length > 0 ? (
                    props.options.map((option) => (
                        // @ts-expect-error MUI issue with objects
                        <MenuItem key={option.label} value={option.value}>
                            <Checkbox
                                checked={props.value.includes(option.value)}
                            />
                            <ListItemText
                                primary={option.label}
                                sx={{ ...LIGHT_TEXT }}
                            />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem value="" key="noData">
                        {t('common.noData')}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

MultiSelectWithCheckboxes.defaultProps = {
    disabled: false,
    fullWidth: false,
    variant: 'standard',
    isSelectAllActive: false,
    onSelectAll: undefined,
    style: {},
    loading: undefined,
};
