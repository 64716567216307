import * as React from 'react';

import { Box, Card, Tooltip, Typography } from '@mui/material';

import { DEFAULT_SHADOW } from 'styles/theme';
import { NoColorLink } from '../NoColorLink';
import { styled } from '@mui/material/styles';

type Props = {
    title: string;
    route?: string;
    action: React.ReactNode;
    icon: React.ReactNode;
    onClick?: () => void;
    className?: string;
};

const minTooltipLength = 20;

const StyTooltipText = styled(Typography, {
    name: 'StyTooltipText',
})({
    // creates ellipsis but on 3 lines
    // see https://css-tricks.com/almanac/properties/l/line-clamp/
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    // we use max height for old Firefox version that do not support line clamp
    maxHeight: '57px',
    color: 'inherit',
    wordBreak: 'break-word',
});

const StyGenericCardContainer = styled(Card, {
    name: 'StyGenericCardContainer',
})(({ theme }) => ({
    ...DEFAULT_SHADOW,
    height: '96px',
    padding: '0 1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '400px',
    color: theme.palette.darkGrey,
}));

const GenericCardContainer = (props: {
    children: React.ReactNode;
    className?: string;
}) => {
    const { children, className, ...otherProps } = props;
    return (
        <StyGenericCardContainer className={className} {...otherProps}>
            {children}
        </StyGenericCardContainer>
    );
};

const StyIconAndTextContainer = styled(Box, {
    name: 'StyIconAndTextContainer',
    shouldForwardProp: (prop) => prop !== 'styledAction',
})<
    { styledAction?: Props['action'] } & React.ComponentProps<
        typeof NoColorLink
    >
>(({ styledAction }) => ({
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    marginLeft: '-1rem',
    paddingLeft: '1rem',
    ...(styledAction
        ? {}
        : {
              marginRight: '-1rem',
              paddingRight: '1rem',
          }),
}));

const StyCardIcon: React.ElementType = styled('div', {
    name: 'StyCardIcon',
})({ marginRight: '0.8rem', display: 'flex' });

const GenericCard = ({
    title,
    route,
    onClick,
    icon,
    action,
    className,
}: Props): React.ReactElement => {
    const text = (
        <Tooltip
            title={title && title.length >= minTooltipLength ? title : ''}
            enterDelay={500}
        >
            <StyTooltipText variant="h5">{title}</StyTooltipText>
        </Tooltip>
    );
    return (
        <GenericCardContainer className={className}>
            <StyIconAndTextContainer
                component={NoColorLink}
                styledAction={action}
                to={route || ''}
                onClick={onClick}
            >
                <StyCardIcon>{icon}</StyCardIcon>
                {text}
            </StyIconAndTextContainer>
            <Box sx={{ ml: '0.5rem' }}>{action}</Box>
        </GenericCardContainer>
    );
};

GenericCard.defaultProps = {
    onClick: () => {},
};

export default GenericCard;
