export default {
    // Set Filter
    selectAll: '(Tout sélectionner)',
    selectAllSearchResults: '(Sélectionner tous les résultats)',
    searchOoo: 'Rechercher...',
    blanks: '(Vide)',
    noMatches: 'Aucune correspondance',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    equals: 'Egal',
    notEqual: 'Différent',
    empty: 'En sélectionner',

    // Number Filter
    lessThan: 'Inférieur à',
    greaterThan: 'Supérieur à',
    lessThanOrEqual: 'Inférieur ou égal à',
    greaterThanOrEqual: 'Supérieur ou égal à',
    inRange: "Dans l'intervalle",
    inRangeStart: 'à',
    inRangeEnd: 'de',

    // Text Filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Termine par',
    blank: 'Vide',
    notBlank: 'Non vide',

    // Date Filter
    dateFormatOoo: 'aaaa-mm-jj',

    // Filter Conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // Filter Buttons
    applyFilter: 'Appliquer',
    resetFilter: 'Réinitialiser',
    clearFilter: 'Vider',
    cancelFilter: 'Annuler',

    // Filter Titles
    textFilter: 'Filtre text',
    numberFilter: 'Filtre nombre',
    dateFilter: 'Filtre date',
    setFilter: 'Définir le filtre',

    // Side Bar
    columns: 'Ouvrir/fermer le panneau de configuration',
    filters: 'Filtres',

    // columns tool panel
    pivotMode: 'Mode pivot',
    groups: 'Groupes de lignes',
    rowGroupColumnsEmptyMessage: 'Déplacer ici pour grouper',
    values: 'Valeurs',
    valueColumnsEmptyMessage: 'Déplacer ici pour agréger',
    pivots: 'Labels des colonnes',
    pivotColumnsEmptyMessage:
        'Déplacer ici pour définir les labels des colonnes',

    // Header of the Default Group Column
    group: 'Groupe',

    // Other
    loadingOoo: 'Chargement...',
    noRowsToShow: 'Aucune ligne',
    enabled: 'Activé',

    // Menu
    pinColumn: 'Epingler la colonne',
    pinLeft: 'Epingle gauche',
    pinRight: 'Epingle droite',
    noPin: 'Sans épingle',
    valueAggregation: 'Agrégation de valeur',
    autosizeThiscolumn: 'Taille automatique pour cette colonne',
    autosizeAllColumns: 'Taille automatique pour toutes les colonnes',
    groupBy: 'Grouper par',
    ungroupBy: 'Dé-grouper par',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Tout ouvrir',
    collapseAll: 'Tout fermer',
    copy: 'Copier',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'Copier avec les noms des colonnes',
    paste: 'Coller',
    ctrlV: 'Ctrl+V',
    export: 'Exporter',
    csvExport: 'Export en CSV',
    excelExport: 'Export en Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'Somme',
    realizedRateAggFunc: 'Somme (de %)',
    first: 'Premier',
    last: 'Dernier',
    min: 'Min',
    max: 'Max',
    none: 'Aucun',
    count: 'Compte',
    avg: 'Moyenne',
    filteredRows: 'Filtrées',
    selectedRows: 'Sélectionnées',
    totalRows: 'Total des lignes',
    totalAndFilteredRows: 'Lignes',
    more: 'Plus',
    to: 'à',
    of: 'de',
    page: 'Page',
    nextPage: 'Page suivante',
    lastPage: 'Dernière page',
    firstPage: 'Première Page',
    previousPage: 'Page précédente',
    evolutionAggFunc: 'Somme (de %)',
    fractionAggFunc: 'Taux',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Graphique pivot & mode pivot',
    pivotChart: 'Graphique pivot',
    chartRange: 'Légende du graphique',

    columnChart: 'Colonne',
    groupedColumn: 'Groupé',
    stackedColumn: 'Accumulé',
    normalizedColumn: '100% accumulé',

    barChart: 'Barre',
    groupedBar: 'Groupé',
    stackedBar: 'Accumulé',
    normalizedBar: '100% accumulé',

    pieChart: 'Camembert',
    pie: 'Camembert',
    doughnut: 'Anneau',

    line: 'Courbe',

    xyChart: 'X Y (Points)',
    scatter: 'Points',
    bubble: 'Bulle',

    areaChart: 'Aire',
    area: 'Aire',
    stackedArea: 'Accumulé',
    normalizedArea: '100% accumulé',

    histogramChart: 'Histogramme',

    // Charts
    pivotChartTitle: 'Graphique pivot',
    rangeChartTitle: 'Echelle du graphique',
    settings: 'Paramètres',
    data: 'Données',
    format: 'Format',
    categories: 'Catégories',
    defaultCategory: '(Aucune)',
    series: 'Séries',
    xyValues: 'X Y valeurs',
    paired: 'Mode paire',
    axis: 'Axe',
    navigator: 'Navigateur',
    color: 'Couleur',
    thickness: 'Epaisseur',
    xType: 'X type',
    automatic: 'Automatique',
    category: 'Catégorie',
    number: 'Nombre',
    time: 'Temps',
    xRotation: 'X rotation',
    yRotation: 'Y rotation',
    ticks: 'Graduations',
    width: 'Largeur',
    height: 'Hauteur',
    length: 'Longueur',
    padding: 'Espacement',
    spacing: 'Espacement',
    chart: 'Graphique',
    title: 'Titre',
    titlePlaceholder: 'Titre du graphique - double clic pour modifier',
    background: 'Arrière plan',
    font: 'Police',
    top: 'Haut',
    right: 'Droite',
    bottom: 'Bas',
    left: 'Gauche',
    labels: 'Labels',
    size: 'Taille',
    minSize: 'Taille min',
    maxSize: 'Taille max',
    legend: 'Légende',
    position: 'Position',
    markerSize: 'Taille du marqueur',
    markerStroke: 'Epaisseur du marqueur',
    markerPadding: 'Espacement du marqueur',
    itemSpacing: "Espacement de l'objet",
    itemPaddingX: "Espacement X de l'objet",
    itemPaddingY: "Espacement Y de l'objet",
    layoutHorizontalSpacing: 'Espacement horizontal',
    layoutVerticalSpacing: 'Espacement vertical',
    strokeWidth: 'Epaisseur',
    offset: 'Décalage',
    offsets: 'Décalages',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'Décalage X',
    yOffset: 'Décalage Y',
    lineWidth: 'Largeur de la courbe',
    normal: 'Normal',
    bold: 'Gras',
    italic: 'Italique',
    boldItalic: 'Italique gras',
    predefined: 'Prédéfini',
    fillOpacity: 'Opacité du remplissage',
    strokeOpacity: 'Opacité de la courbe',
    histogramBinCount: 'Compte',
    columnGroup: 'Colonne',
    barGroup: 'Barre',
    pieGroup: 'Camembert',
    lineGroup: 'Courbe',
    scatterGroup: 'X Y (Points)',
    areaGroup: 'Aire',
    histogramGroup: 'Histogramme',
    groupedColumnTooltip: 'Groupé',
    stackedColumnTooltip: 'Accumulé',
    normalizedColumnTooltip: '100% accumulé',
    groupedBarTooltip: 'Groupé',
    stackedBarTooltip: 'Accumulé',
    normalizedBarTooltip: '100% accumulé',
    pieTooltip: 'Camembert',
    doughnutTooltip: 'Anneau',
    lineTooltip: 'Courbe',
    groupedAreaTooltip: 'Aire',
    stackedAreaTooltip: 'Aire accumulé',
    normalizedAreaTooltip: '100% accumulé',
    scatterTooltip: 'Points',
    bubbleTooltip: 'Bulle',
    histogramTooltip: 'Histogramme',
    noDataToChart: 'Aucune donnée disponible.',
    pivotChartRequiresPivotMode: 'Graphique pivot requiert le mode pivot.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Lié au graphique',
    chartUnlinkToolbarTooltip: 'Non lié au graphique',
    chartDownloadToolbarTooltip: 'Télécharger le graphique',

    // ARIA
    ariaHidden: 'caché',
    ariaVisible: 'visible',
    ariaChecked: 'coché',
    ariaUnchecked: 'décoché',
    ariaIndeterminate: 'indeterminé',
    ariaColumnSelectAll: 'Sélectionner toutes les colonnes',
    ariaInputEditor: 'Editeur de champ',
    ariaDateFilterInput: 'Champ de filtre date',
    ariaFilterInput: 'Champ de filtre',
    ariaFilterColumnsInput: 'Champ de filtre des colonnes',
    ariaFilterValue: 'Filtrer la valeur',
    ariaFilterFromValue: 'Filtrer depuis la valeur',
    ariaFilterToValue: "Filtrer jusqu'à la valeur",
    ariaFilteringOperator: 'Opérateur de filtre',
    ariaColumnToggleVisibility: 'inverser la visibilité de la colonne',
    ariaColumnGroupToggleVisibility:
        'inverser la visibilité du groupe de colonne',
    ariaRowSelect: 'Appuyer sur ESPACE pour sélectionner cette ligne',
    ariaRowDeselect: 'Appuyer sur ESPACE pour désélectionner cette ligne',
    ariaRowToggleSelection:
        'Appuyer sur ESPACE pour inverser la sélection de ligne',
    ariaRowSelectAll:
        'Appuyer sur ESPACE pour inverser la sélection de toutes les lignes',
    ariaSearch: 'Chercher',
    ariaSearchFilterValues: 'Chercher les valeurs filtrées',
};
