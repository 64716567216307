import * as React from 'react';

import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import DotIcon from '@mui/icons-material/FiberManualRecord';

import { MANTY_BLACK } from 'styles/theme';
import { IconProps, styled } from '@mui/material';
import { Editor } from '@tiptap/core';
import EditorIcon from './EditorIcon';

const StyEditorIcon = styled(EditorIcon, {
    name: 'ColorPickerStyEditorIcon',
    shouldForwardProp: (prop: keyof IconProps) => prop !== 'color',
})(({ color }) => ({
    '& svg': {
        color,
        fill: color,
    },
}));

export default function ColorPicker({
    editor,
}: {
    editor: Editor;
}): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
    const close = () => setAnchorEl(null);

    const colors = [
        'rgb(55, 53, 47)',
        'rgb(155, 154, 151)',
        'rgb(100, 71, 58)',
        'rgb(217, 115, 13)',
        'rgb(223, 171, 1)',
        'rgb(15, 123, 108)',
        'rgb(11, 110, 153)',
        'rgb(105, 64, 165)',
        'rgb(173, 26, 114)',
        'rgb(224, 62, 62)',
    ];
    const currentColor =
        colors
            .filter((color) => editor.isActive('textStyle', { color }))
            .pop() || MANTY_BLACK;

    return (
        <>
            <FormatColorTextIcon
                sx={{ fontSize: '1.8rem', scale: '0.8', fill: currentColor }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={close}
            >
                <IconButton
                    size="small"
                    onClick={() => {
                        editor.chain().focus().unsetColor().run();
                        close();
                    }}
                >
                    <DotIcon sx={{ fill: MANTY_BLACK }} />
                </IconButton>
                {colors.map((color) => (
                    <StyEditorIcon
                        key={`color-${color}`}
                        color={color}
                        onClick={() => {
                            editor.chain().focus().setColor(color).run();
                            close();
                        }}
                    >
                        <DotIcon />
                    </StyEditorIcon>
                ))}
            </Popover>
        </>
    );
}
