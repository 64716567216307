import * as React from 'react';
import { DashboardT } from 'business/definitions';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardEditMenu from 'components/DashboardEditMenu/DashboardEditMenu';
import { MANTY_ORANGE } from 'styles/theme';
import GenericCard from 'ui/Card/GenericCard';
import { useToggleFavouriteDashboard } from 'routes/Home/hooks/favouriteDashboards';
import { useEffect } from 'react';

type Props = {
    dashboard: DashboardT;
    allowMove: boolean;
    icon?: React.ReactNode;
    onClick: () => void;
    onPropertyChange?: (
        dashboard: DashboardT,
        property: keyof DashboardT,
        value: boolean,
    ) => void;
};

const DashboardCard = ({
    dashboard,
    allowMove,
    icon,
    onClick,
    onPropertyChange,
}: Props): React.ReactElement => {
    const [isInPresentationMode, setIsInPresentationMode] = React.useState(
        dashboard.isInPresentationMode,
    );

    const [isFavourite, setIsFavourite] = React.useState(
        dashboard.isUserFavourite,
    );

    useEffect(() => {
        setIsInPresentationMode(dashboard.isInPresentationMode);
    }, [dashboard.isInPresentationMode]);

    useEffect(() => {
        setIsFavourite(dashboard.isUserFavourite);
    }, [dashboard.isUserFavourite]);

    const onSuccess = () => {
        if (onPropertyChange) {
            onPropertyChange(dashboard, 'isUserFavourite', !isFavourite);
        }
        setIsFavourite(!isFavourite);
    };

    const { mutate: toggleFavouriteDashboard } = useToggleFavouriteDashboard(
        dashboard.key,
        onSuccess,
    );

    const togglePresentationMode = () => {
        if (onPropertyChange) {
            onPropertyChange(
                dashboard,
                'isInPresentationMode',
                !isInPresentationMode,
            );
        }
        setIsInPresentationMode((prevState) => !prevState);
    };

    const toggleFavourite = () => {
        if (!dashboard.key) {
            console.error(
                'dashboard.key should be defined in onToggleFavourite',
            );
            return;
        }
        toggleFavouriteDashboard(!isFavourite);
    };

    return (
        <GenericCard
            title={dashboard.title}
            icon={icon || <DashboardIcon sx={{ color: MANTY_ORANGE }} />}
            route={`/dashboard/${dashboard.slug}`}
            action={
                <DashboardEditMenu
                    dashboard={dashboard}
                    show
                    small
                    allowMove={allowMove}
                    isInPresentationMode={isInPresentationMode}
                    onPresentationModeChange={togglePresentationMode}
                    isFavourite={isFavourite}
                    onFavouriteChange={toggleFavourite}
                />
            }
            onClick={onClick}
            className="pendo-dashboard-card"
        />
    );
};

DashboardCard.defaultProps = {
    allowMove: false,
    onClick: () => {},
};

export default DashboardCard;
