import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Breakpoint, Dialog, DialogProps } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import { Variant } from '@mui/material/styles/createTypography';

type Props = {
    children: React.ReactNode;
    modalTitle: string;
    openModal: boolean;
    onAction: () => void;
    onCloseModal: () => void;
    actionText: string;
    disableActionButton?: boolean;
    maxWidth?: false | Breakpoint | undefined;
    // Prop to specify the html variant to use for the modal title
    titleVariant?: Variant;
    // Props of the underlying Dialog Component
    dialogProps?: Partial<DialogProps> | undefined;
    // Prop to add className to track action button on Pendo
    pendoActionButtonClassName?: string;
};

function ModalTemplate(props: Props): React.ReactElement {
    const { t } = useTranslation();
    const {
        openModal,
        children,
        modalTitle,
        onCloseModal,
        actionText,
        onAction,
        disableActionButton,
        maxWidth,
        titleVariant,
        dialogProps,
        pendoActionButtonClassName,
    } = props;
    return (
        <Dialog fullWidth maxWidth={maxWidth} open={openModal} {...dialogProps}>
            <DialogTitle
                sx={{ mt: '8px', whiteSpace: 'pre-wrap' }}
                variant={titleVariant}
            >
                {modalTitle}
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ mr: '1.3rem' }}>
                <Button
                    onClick={onCloseModal}
                    className="e2e-close-raw-data-button"
                >
                    {t('common.close')}
                </Button>
                <Button
                    variant="contained"
                    onClick={onAction}
                    data-testid="e2e-action-raw-data-button"
                    disabled={disableActionButton}
                    className={pendoActionButtonClassName}
                >
                    {actionText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
ModalTemplate.defaultProps = {
    disableActionButton: false,
    maxWidth: 'sm',
    titleVariant: 'body1',
    dialogProps: undefined,
};
export default ModalTemplate;
