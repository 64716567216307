import React, { Component } from 'react';
import { debounce, get } from 'lodash';

type Props = {
    value: any;
    innerRef: React.ForwardedRef<HTMLElement>;
};
type State = {
    debouncedValue: any;
    isEditing: boolean;
};

/*
	Notes:
	- we're calling the handler with event.target.value directly
		because event.target is not kept (it's set to null once the delay has passed)
	- We're using a React.Component because it didn't work with a functional component
		as callHandler needs to be defined once
*/
export default (
    WrappedComponent: Component,
    handlerName: string = 'onChange',
    path: string = 'target.value',
    delay: number = 1000,
): any => {
    // TODO: improve typing
    class WithDebounce extends Component<Props, State> {
        callHandler: Function;

        constructor(props: Props) {
            super(props);
            this.state = { debouncedValue: props.value, isEditing: false };
            // @ts-expect-error [TS migration] (previously $FlowFixMe) property exists
            const handler = (value) => {
                // @ts-expect-error fixing this seems quite involved for now - indeed typing this kind of HOC correctly seems painful
                props[handlerName](value);
                this.setState((prevState) => ({
                    ...prevState,
                    isEditing: false,
                }));
            };
            this.callHandler = debounce(handler, delay);
        }

        componentDidUpdate(prevProps: Readonly<Props>): void {
            if (prevProps.value !== this.props.value) {
                // isEditing is used to prevent the user edition from being overridden by a an update to the `value` prop
                // example: the prop value can be fed by the response to a CRUD endpoint, and override what the user was typing
                // between the start of the request, and the reception of the response.
                if (
                    this.props.value !== this.state.debouncedValue &&
                    !this.state.isEditing
                ) {
                    this.setState({ debouncedValue: this.props.value });
                }
            }
        }

        debounceHandler(event: any) {
            const debouncedValue = get(event, path, null);

            this.setState({ debouncedValue, isEditing: true });
            this.callHandler(debouncedValue);
        }

        render() {
            return (
                // @ts-expect-error [TS migration] (previously $FlowFixMe)
                <WrappedComponent
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...this.props}
                    value={this.state.debouncedValue}
                    ref={this.props.innerRef}
                    {...{ [handlerName]: this.debounceHandler.bind(this) }}
                />
            );
        }
    }
    return React.forwardRef<HTMLElement, Omit<Props, 'innerRef'>>(
        (props, ref) => <WithDebounce innerRef={ref} {...props} />,
    );
};
