import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import SearchIcon from '@mui/icons-material/Search';
import { makeTransitions, SHADOW } from 'styles/theme';
import { styled, SxProps } from '@mui/material';

type Props = {
    sx?: SxProps;
    placeholder?: string;
    handleChange: (arg1: string) => void;
    autoFocus?: boolean;
    testid?: string;
    width?: string;
};

const StySearchBar = styled('div', {
    name: 'StySearchBar',
})(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    borderRadius: '1.25rem',
    height: '2.5rem',
    backgroundColor: theme.palette.lightGrey,

    transition: makeTransitions(['box-shadow']),
    '&:hover': { boxShadow: SHADOW },
}));

const StyInput = styled('input', {
    name: 'StyInput',
    shouldForwardProp: (prop: keyof Props) => prop !== 'width',
})<{ width: Props['width'] }>(({ theme, width = '12rem' }) => ({
    border: '0',
    borderRadius: '36px',
    color: theme.palette.black,
    backgroundColor: theme.palette.lightGrey,
    fontSize: '14px',
    fontWeight: '400',
    outline: 'none',
    width,
}));

const applySearch = (search: string, callback: (arg1: string) => void) => {
    callback(search);
};

const debouncedSearch = _.debounce(applySearch, 500);

const SearchBar = (props: Props): React.ReactElement => {
    const { placeholder = '', sx } = props;
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (props.autoFocus && inputRef && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <StySearchBar sx={sx}>
            <SearchIcon sx={{ color: 'black', ml: '1rem' }} />
            <StyInput
                ref={inputRef}
                type="text"
                placeholder={placeholder}
                onChange={(event) =>
                    debouncedSearch(event.target.value, props.handleChange)
                }
                data-testid={props.testid}
                width={props.width}
            />
        </StySearchBar>
    );
};
export default SearchBar;
