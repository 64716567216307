import { ValueGetterParams } from 'ag-grid-community';
import { camelize } from 'helpers/syntaxHelper';
import {
    REALIZED_FIELD,
    VOTED_TOTAL_FIELD,
} from 'routes/BudgetModule/config/constants';

export const valueGetter = (params: ValueGetterParams<ResponseDataT>): any =>
    (params?.data && params.data[params.colDef.field || '']) || '';

export const percentageGetter = (
    params: ValueGetterParams<ResponseDataT>,
): number => 100 * valueGetter(params);

// see docs here: https://www.ag-grid.com/react-grid/aggregation/#example-3---multi-column-aggregation
// This getter is specific for the percent_realized field
export const realizedRateValueGetter = (
    params: ValueGetterParams<ResponseDataT>,
) => {
    if (!params.data) return;
    const realized = params.data[camelize(REALIZED_FIELD)];
    const voted = params.data[camelize(VOTED_TOTAL_FIELD)];
    if (!params.node?.group) {
        const isInvalidRatio =
            !realized ||
            !voted ||
            Number.isNaN(realized) ||
            Number.isNaN(voted);
        // @ts-expect-error data is not well typed
        const ratio = isInvalidRatio ? 0 : realized / voted;
        // eslint-disable-next-line consistent-return
        return {
            voted,
            realized,
            value: ratio,
            toString: () => ratio,
        };
    }
};
