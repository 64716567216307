import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';

/**
 * A MUI Link connected to react-router without the blue coloring nor the underlining
 */

const NoColorLink = (
    props: React.ComponentProps<typeof RouterLink>,
): React.ReactElement => (
    <Link underline="none" color="inherit" component={RouterLink} {...props} />
);

export { NoColorLink };
