import React from 'react';
import MuiToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { styled } from '@mui/material';

const StyToggleButton = styled(MuiToggleButton, {
    name: 'StyTestToggleButton',
})(({ theme }) => ({
    borderRadius: '28px !important',
    color: theme.palette.black,
    '&:hover': {
        backgroundColor: 'inherit',
    },
    textTransform: 'inherit', // remove uppercase on all buttons
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        '& h4': {
            color: 'white',
        },
        '& h5': {
            color: 'white',
        },
    },
    '&.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.main,
    },
    '&.Mui-disabled': {
        '& h4': {
            color: theme.palette.lightGrey,
        },
        '& h5': {
            color: theme.palette.lightGrey,
        },
    },
}));

const ToggleButton = (props: ToggleButtonProps): React.ReactElement => (
    <StyToggleButton {...props} />
);

export default ToggleButton;
